import * as React from "react";
import { useCallback } from "react";
import HeaderTerms from "../components/header-terms";
import TermsContent from "../components/terms-content";
import Footer1 from "../components/footer1";
import styled from "styled-components";

const TermsOfServiceRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const TermsOfService = () => {
  

  return (
    <TermsOfServiceRoot>
      <HeaderTerms />
      <TermsContent />
      <Footer1 />
    </TermsOfServiceRoot>
  );
};

export default TermsOfService;

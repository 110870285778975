import * as React from "react";
import styled from "styled-components";

const Paragraph = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem var(--padding-base);
`;
const Paragraph1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const Content1 = styled.div`
  width: 48rem;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem var(--padding-base);
  box-sizing: border-box;
`;
const Heading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
`;
const Content2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) 0rem;
  font-size: var(--heading-desktop-h5-size);
  font-family: var(--heading-desktop-h3);
`;
const YouAreGranted = styled.span``;
const Unauthorised = styled.b``;
const YouAgreeNot = styled.p`
  margin-block-start: 0;
  margin-block-end: 15px;
`;
const UseTheWebsite = styled.li`
  margin-bottom: ;
`;
const ViolateAnyApplicable = styled.li``;
const UseTheWebsiteForAnyCommer = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-2xl);
`;
const RichText = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50rem;
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;
const ContentRoot = styled.div`
  width: 100%;
  background-color: var(--text-alternate);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl) var(--padding-93xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--text-regular-semi-bold-size);
  color: var(--border-primary);
  font-family: var(--text-regular-semi-bold);
`;

const TermsContent = () => {
  return (
    <ContentRoot>
      <Content3>
        <RichText>
          <Content>
            <Paragraph>
              These Terms of Use ("Terms") govern your access to and use of
              idminternational.com.au ("Website"). By accessing or using the
              Website, you agree to be bound by these Terms. If you disagree
              with any part of these Terms, you may not access or use the
              Website.
            </Paragraph>
          </Content>
          <Content1>
            <Paragraph1>
              Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
              suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
              quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
              posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
              feugiat sapien varius id.
            </Paragraph1>
          </Content1>
          <Content2>
            <Heading>1. Use of the Website</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              <YouAreGranted>{`You are granted a non-exclusive, revocable license to access and use the Website in accordance with these Terms. You may not use the Website for any illegal or `}</YouAreGranted>
              <Unauthorised>unauthorised</Unauthorised>
              <YouAreGranted>
                {" "}
                purpose. You agree not to interfere with or disrupt the Website
                or any servers or networks connected to the Website.
              </YouAreGranted>
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>2. Intellectual Property Rights</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              The Website and all content included on the Website, such as text,
              graphics, logos, images, and software, are the property of IDM
              International Ltd or its licensors and are protected by copyright,
              trademark, and other intellectual property laws. You may not
              modify, reproduce, distribute, create derivative works of, or
              publicly display any content from the Website without the prior
              written consent of IDM International Ltd.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>3. Restrictions on Use</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              <YouAgreeNot>You agree not to:</YouAgreeNot>
              <UseTheWebsiteForAnyCommer>
                <UseTheWebsite>
                  Use the Website for any commercial purpose without our express
                  written consent.
                </UseTheWebsite>
                <UseTheWebsite>
                  Collect or harvest any personal information of other users.
                </UseTheWebsite>
                <UseTheWebsite>
                  Transmit any content that is unlawful, harmful, threatening,
                  abusive, harassing, defamatory, vulgar, obscene, hateful, or
                  racially or ethnically offensive.
                </UseTheWebsite>
                <UseTheWebsite>
                  Use the Website to impersonate any person or entity.
                </UseTheWebsite>
                <UseTheWebsite>
                  Interfere with or disrupt the Website or any servers or
                  networks connected to the Website.
                </UseTheWebsite>
                <ViolateAnyApplicable>
                  Violate any applicable laws or regulations.
                </ViolateAnyApplicable>
              </UseTheWebsiteForAnyCommer>
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>4. Disclaimer of Warranties</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              The Website is provided "as is" and without warranties of any
              kind, whether express or implied. IDM International Ltd disclaims
              all warranties, including, but not limited to, warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement. IDM International Ltd does not warrant that the
              Website will be uninterrupted or error-free, that defects will be
              corrected, or that the Website or the server that makes it
              available is free of viruses or other harmful components.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>5. Limitation of Liability</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              In no event shall IDM International Ltd be liable for any damages
              arising out of or related to your use of the Website, including,
              but not limited to, direct, indirect, incidental, consequential,
              punitive, or special damages.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>6. Indemnification</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              You agree to indemnify and hold harmless IDM International Ltd and
              its officers, directors, employees, agents, and licensors from and
              against any and all claims, losses, damages, liabilities, costs,
              and expenses (including attorneys' fees) arising out of or related
              to your use of the Website or your violation of these Terms.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>7. Changes to the Terms of Use</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              IDM International Ltd may revise these Terms at any time by
              posting the revised Terms on the Website. You are bound by any
              such revisions. You should periodically visit this page to review
              the current Terms.
            </Paragraph1>
          </Content>
          <Content2>
            <Heading>8. Governing Law and Jurisdiction</Heading>
          </Content2>
          <Content>
            <Paragraph1>
              These Terms shall be governed by and construed in accordance with
              the laws of Western Australia, without regard to its conflict of
              law provisions. Any legal action or proceeding arising out of or
              relating to these Terms shall be brought exclusively in the courts
              of Western Australia.
            </Paragraph1>
          </Content>
        </RichText>
      </Content3>
    </ContentRoot>
  );
};

export default TermsContent;
